import React from 'react';
interface RestaurantIconProps {
    width: string;
    height: string;
    fill: string;
}

export const Restaurant = ({ width, height, fill }: RestaurantIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.54772 1.76709C5.39076 1.61033 5.18198 1.51625 4.96058 1.50251C4.70429 1.49944 4.45765 1.60015 4.27676 1.78173C4.09586 1.9633 3.99609 2.21032 4.00012 2.46659V10.5634C4.00048 11.5641 4.81161 12.3753 5.8123 12.3756C6.41243 12.3765 6.89871 12.8628 6.89961 13.4629V21.2553C6.90037 21.9556 7.46787 22.5231 8.16814 22.5238H8.89301C9.59329 22.5231 10.1608 21.9556 10.1615 21.2553V13.4629C10.1612 13.1744 10.2756 12.8976 10.4796 12.6937C10.6836 12.4897 10.9604 12.3752 11.2489 12.3756C12.2495 12.3753 13.0607 11.5641 13.061 10.5634V2.4086C13.0619 1.92867 12.6884 1.5313 12.2093 1.50251C11.953 1.49944 11.7064 1.60015 11.5255 1.78173C11.3446 1.9633 11.2448 2.21032 11.2489 2.46659V8.75125C11.2483 8.95117 11.0863 9.1131 10.8864 9.11368H9.79911C9.59918 9.1131 9.43725 8.95117 9.43667 8.75125V2.4086C9.43753 1.92867 9.06402 1.5313 8.58494 1.50251C8.32866 1.49944 8.08202 1.60015 7.90113 1.78173C7.72023 1.9633 7.62046 2.21032 7.62449 2.46659V8.75125C7.62391 8.95117 7.46198 9.1131 7.26205 9.11368H6.17474C5.97481 9.1131 5.81288 8.95117 5.8123 8.75125V2.4086C5.81265 2.16814 5.71748 1.93739 5.54772 1.76709Z"
                fill={fill}
            />
            <path
                d="M19.7895 1.53483C18.8654 2.02862 18.0659 2.72645 17.4518 3.57535C16.6661 4.59061 16.1443 5.78482 15.9332 7.05112L15.8897 7.30845L15.8462 7.56578C15.7599 8.05388 15.7186 8.54888 15.723 9.04453C15.727 9.53925 15.773 10.0327 15.8607 10.5196C16.1211 11.8946 16.4762 13.2499 16.9234 14.576C16.9992 14.7992 17.0381 15.0334 17.0386 15.2691V21.255C17.0386 21.5915 17.1723 21.9141 17.4102 22.152C17.648 22.3899 17.9707 22.5236 18.3071 22.5236H19.032C19.3684 22.5236 19.6911 22.3899 19.929 22.152C20.1669 21.9141 20.3005 21.5915 20.3005 21.255V8.2073L20.3114 1.86465C20.3109 1.73959 20.2466 1.62342 20.1409 1.55661C20.0352 1.4898 19.9027 1.48159 19.7895 1.53483Z"
                fill={fill}
            />
        </svg>
    );
};
