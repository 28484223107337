import React from 'react';
interface HospitalIconProps {
    width: string;
    height: string;
    fill: string;
}

export const Hospital = ({ width, height, fill }: HospitalIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.4436 9.47314L12.449 2.20443C12.0856 1.93186 11.7222 1.93186 11.3587 2.20443L1.3642 9.47314C0.637316 9.92745 1.09163 11.1086 1.90936 11.1086H3.72656V21.1031C3.72656 21.6483 4.08998 22.0117 4.63514 22.0117H19.1726C19.7178 22.0117 20.0812 21.6483 20.0812 21.1031V11.1086H21.8984C22.7162 11.1086 23.1704 9.92745 22.4436 9.47314ZM11.9039 20.1946C8.90554 20.1946 6.45234 17.7414 6.45234 14.743C6.45234 11.7447 8.90554 9.29145 11.9039 9.29145C14.9023 9.29145 17.3555 11.7447 17.3555 14.743C17.3555 17.7414 14.9023 20.1946 11.9039 20.1946Z"
                fill={fill}
            />
            <path
                d="M11.9043 12.0059V17.4578"
                stroke={fill}
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.17773 14.7319H14.6297"
                stroke={fill}
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
