import { NumberWithText } from 'components/NumberWithText/NumberWithText';
import React from 'react';
import {
    AdvantagesType,
    ImageType,
    TitleDesriptionType,
} from 'types/CommonTypes';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    BottomWrapper,
    ColumnTitle,
    ColumnWrapper,
    IconsWrapper,
    Image,
    ImageColumnWrapper,
    LocationSectionWrapper,
    LocationWrapper,
    MarkdownWrapper,
    MiddleTextWrapper,
    NumbersWrapper,
    TextWrapper,
    Title,
    UpperTitle,
    UpperWrapper,
} from './Location.styled';

export interface LocationProps {
    advantagesLeft: AdvantagesType[];
    advantagesRight: AdvantagesType[];
    descriptionLeft: string;
    descriptionRight: string;
    titleRight: string;
    imageLeft: ImageType;
    imageRight: ImageType;
    keyFeatures: TitleDesriptionType[];
    keyFeaturesTitle: string;
}

export const Location = ({
    advantagesLeft,
    advantagesRight,
    descriptionLeft,
    descriptionRight,
    titleRight,
    imageLeft,
    imageRight,
    keyFeatures,
    keyFeaturesTitle,
}: LocationProps) => {
    const isMobile = useBreakpoint('sm');
    return (
        <LocationWrapper>
            <LocationSectionWrapper>
                <UpperWrapper>
                    <Image
                        src={imageLeft?.sourceUrl}
                        alt={imageLeft?.altText}
                    />
                    <TextWrapper>
                        <Title>{titleRight}</Title>
                        <MarkdownWrapper
                            markdown={descriptionRight}
                            smallText
                        />
                        {!isMobile && (
                            <IconsWrapper>
                                {advantagesRight?.map((item) => (
                                    <ColumnWrapper>
                                        <ImageColumnWrapper>
                                            <img
                                                src={item.icon?.sourceUrl}
                                                alt={item.icon?.altText}
                                            />
                                        </ImageColumnWrapper>
                                        <ColumnTitle>{item?.title}</ColumnTitle>
                                    </ColumnWrapper>
                                ))}
                            </IconsWrapper>
                        )}
                    </TextWrapper>
                    {isMobile && (
                        <IconsWrapper>
                            {advantagesRight?.map((item) => (
                                <ColumnWrapper>
                                    <ImageColumnWrapper>
                                        <img
                                            src={item.icon?.sourceUrl}
                                            alt={item.icon?.altText}
                                        />
                                    </ImageColumnWrapper>
                                    <ColumnTitle>{item?.title}</ColumnTitle>
                                </ColumnWrapper>
                            ))}
                        </IconsWrapper>
                    )}
                </UpperWrapper>
                <UpperWrapper>
                    <MiddleTextWrapper>
                        <MarkdownWrapper markdown={descriptionLeft} smallText />
                        {!isMobile && (
                            <IconsWrapper>
                                {advantagesLeft?.map((item) => (
                                    <ColumnWrapper>
                                        <ImageColumnWrapper>
                                            <img
                                                src={item.icon?.sourceUrl}
                                                alt={item.icon?.altText}
                                            />
                                        </ImageColumnWrapper>
                                        <ColumnTitle>{item?.title}</ColumnTitle>
                                    </ColumnWrapper>
                                ))}
                            </IconsWrapper>
                        )}
                    </MiddleTextWrapper>
                    {isMobile && (
                        <IconsWrapper>
                            {advantagesLeft?.map((item) => (
                                <ColumnWrapper>
                                    <ImageColumnWrapper>
                                        <img
                                            src={item.icon?.sourceUrl}
                                            alt={item.icon?.altText}
                                        />
                                    </ImageColumnWrapper>
                                    <ColumnTitle>{item?.title}</ColumnTitle>
                                </ColumnWrapper>
                            ))}
                        </IconsWrapper>
                    )}
                    <Image
                        src={imageRight?.sourceUrl}
                        alt={imageRight?.altText}
                    />
                </UpperWrapper>
            </LocationSectionWrapper>
            <BottomWrapper>
                <UpperTitle>{keyFeaturesTitle}</UpperTitle>
                <NumbersWrapper>
                    {keyFeatures?.map((item) => (
                        <NumberWithText
                            text={item?.description}
                            number={item?.title}
                            bigger
                        />
                    ))}
                </NumbersWrapper>
            </BottomWrapper>
        </LocationWrapper>
    );
};
