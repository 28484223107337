import React from 'react';

interface BusIconProps {
    width: string;
    height: string;
    fill: string;
}

export const Bus = ({ width, height, fill }: BusIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8421 1.5C6.95683 1.5 3 2.05265 3 5.92104V16.9737C3 17.9518 3.43105 18.8195 4.10525 19.4273V21.3948C4.10525 22.0026 4.60262 22.5 5.21049 22.5H6.31579C6.92921 22.5 7.42104 22.0026 7.42104 21.3948V20.2895H16.2632V21.3948C16.2632 22.0026 16.755 22.5 17.3684 22.5H18.4737C19.0815 22.5 19.5789 22.0026 19.5789 21.3948V19.4274C20.2531 18.8195 20.6842 17.9519 20.6842 16.9737V5.92104C20.6842 2.05265 16.7274 1.5 11.8421 1.5ZM6.86844 18.079C5.95105 18.079 5.21055 17.3385 5.21055 16.4211C5.21055 15.5037 5.95105 14.7632 6.86844 14.7632C7.78583 14.7632 8.52634 15.5037 8.52634 16.4211C8.52634 17.3385 7.78578 18.079 6.86844 18.079ZM16.8158 18.079C15.8984 18.079 15.1579 17.3385 15.1579 16.4211C15.1579 15.5037 15.8984 14.7632 16.8158 14.7632C17.7332 14.7632 18.4737 15.5037 18.4737 16.4211C18.4737 17.3385 17.7332 18.079 16.8158 18.079ZM18.4737 11.4474H5.21055V5.92104H18.4737V11.4474Z"
                fill={fill}
            />
        </svg>
    );
};
