import React from 'react';
interface GasStationIconProps {
    width: string;
    height: string;
    fill: string;
}

export const GasStation = ({ width, height, fill }: GasStationIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.967 4.90225L19.8647 2.74171C19.634 2.28963 19.0805 2.11003 18.6283 2.34074C18.1762 2.57141 17.9967 3.12496 18.2274 3.57708L18.9783 5.04897C17.7764 5.44805 16.9067 6.58217 16.9067 7.91637V10.1702C16.9067 10.5509 17.2153 10.8595 17.5961 10.8595H19.2293V13.1686C19.2293 14.1935 18.3954 15.0274 17.3705 15.0274H16.2516V3.19354C16.2516 2.25973 15.4919 1.5 14.5581 1.5H6.43051C5.25389 1.5 4.29663 2.45722 4.29663 3.63384V19.1406C3.55371 19.3197 3 19.9896 3 20.7867V21.8107C3 22.1914 3.3086 22.5 3.68927 22.5H16.859C17.2397 22.5 17.5484 22.1914 17.5484 21.8107V20.7867C17.5484 19.9896 16.9946 19.3197 16.2516 19.1406V16.8654H17.3705C19.4089 16.8654 21.0674 15.207 21.0674 13.1684V5.31992C21.0674 5.17468 21.033 5.03153 20.967 4.90225ZM13.3374 8.36234H7.21088C6.89362 8.36234 6.63645 8.10517 6.63645 7.78791V4.43923C6.63645 4.12197 6.89362 3.8648 7.21088 3.8648H13.3374C13.6547 3.8648 13.9119 4.12197 13.9119 4.43923V7.78795C13.9119 8.10517 13.6547 8.36234 13.3374 8.36234Z"
                fill={fill}
            />
        </svg>
    );
};
