import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import HeroBg from 'images/lokalizacja_bg.png';
import Layout from 'layout/index';
import * as React from 'react';
import { Location } from 'sections/Location/Location';
import { LocationMap } from 'sections/LocationMap/LocationMap';
import { getDisabledPages } from 'utils/utils';

interface LocationPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: LocationPageProps) => {
    const investmentLocationData =
        pageContext?.data?.wpInvestment?.investment?.locationPage;

    const pageTitle = `${pageContext?.title} - ${investmentLocationData?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={
                        investmentLocationData?.titleBackground?.sourceUrl
                            ? investmentLocationData?.titleBackground?.sourceUrl
                            : HeroBg
                    }
                    text={investmentLocationData?.description}
                    title={investmentLocationData?.title}
                />
                <Location
                    advantagesLeft={investmentLocationData?.advantagesLeft}
                    advantagesRight={investmentLocationData?.advantagesRight}
                    imageLeft={investmentLocationData?.imageLeft}
                    imageRight={investmentLocationData?.imageRight}
                    keyFeatures={investmentLocationData?.keyFeatures}
                    keyFeaturesTitle={investmentLocationData?.keyFeaturesTitle}
                    descriptionLeft={investmentLocationData?.descriptionLeft}
                    descriptionRight={investmentLocationData?.descriptionRight}
                    titleRight={investmentLocationData?.titleRight}
                />
                <LocationMap
                    pinIcon={investmentLocationData?.map?.pinIcon}
                    pinPositionLat={investmentLocationData?.map?.pinPositionLat}
                    pinPositionLng={investmentLocationData?.map?.pinPositionLng}
                    pinTitle={investmentLocationData?.map?.pinTitle}
                />
            </>
        </Layout>
    );
};
