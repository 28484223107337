import React, { useState } from 'react';
import {
    LegendBox,
    IconWrapper,
    List,
    ListItem,
    Text,
    Title,
    TitleButton,
    TitleButtonText,
    ArrowWrapper,
} from './MapLegend.styled';
import { Bus } from 'shared/svg/bus';
import { Restaurant } from 'shared/svg/restaurant';
import { GasStation } from 'shared/svg/gas-station';
import { Hospital } from 'shared/svg/hospital';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { ArrowDown } from 'shared/svg/arrows';
import { GroceryStore } from 'shared/svg/grocery-store ';
import { RetailStore } from 'shared/svg/retail-store';
import { School } from 'shared/svg/school';

export const MapLegend = () => {
    const isMobile = useBreakpoint('sm');
    const [legendIsOpen, setLegendIsOpen] = useState(true);

    const iconSize = (): string => {
        return isMobile ? '12' : '16';
    };

    return (
        <LegendBox>
            {isMobile ? (
                <Title>Punkty orientacyjne</Title>
            ) : (
                <TitleButton onClick={() => setLegendIsOpen(!legendIsOpen)}>
                    <TitleButtonText as="span">
                        Punkty orientacyjne
                    </TitleButtonText>
                    <ArrowWrapper isOpen={legendIsOpen}>
                        <ArrowDown width="24" height="24" stroke="#1A233D" />
                    </ArrowWrapper>
                </TitleButton>
            )}
            {(legendIsOpen || isMobile) && (
                <List>
                    <ListItem>
                        <IconWrapper>
                            <GroceryStore
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Sklepy spożywcze</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper>
                            <RetailStore
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Sklepy detaliczne</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper>
                            <School
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Edukacja</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper>
                            <Restaurant
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Restauracje</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper>
                            <Hospital
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Ośrodki zdrowia</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper>
                            <GasStation
                                width={iconSize()}
                                height={iconSize()}
                                fill="#FFFFFF"
                            />
                        </IconWrapper>
                        <Text>Stacje paliw</Text>
                    </ListItem>
                    <ListItem>
                        <IconWrapper isLight>
                            <Bus
                                width={iconSize()}
                                height={iconSize()}
                                fill="#000000"
                            />
                        </IconWrapper>
                        <Text>Przystanki autobusowe</Text>
                    </ListItem>
                </List>
            )}
        </LegendBox>
    );
};
