import { BaseMap } from 'components/BaseMap/BaseMap';
import { MapLegend } from 'components/MapLegend/MapLegend';
import React from 'react';
import { mapStyles } from 'shared/mapStylesLocation';
import { ImageType } from 'types/CommonTypes';
import { MapWrapper } from './LocationMap.styled';

export interface LocationMapProps {
    pinTitle: string;
    pinPositionLat: string;
    pinPositionLng: string;
    pinIcon: ImageType;
}

export const LocationMap = ({
    pinIcon,
    pinPositionLat,
    pinPositionLng,
    pinTitle,
}: LocationMapProps) => {
    const markers = [
        {
            title: pinTitle,
            position: {
                lat: Number(pinPositionLat),
                lng: Number(pinPositionLng),
            },
            iconUrl: pinIcon?.sourceUrl,
        },
    ];

    return (
        <MapWrapper>
            <BaseMap
                mapPosition={{
                    lat: Number(pinPositionLat),
                    lng: Number(pinPositionLng),
                }}
                zoom={18}
                markers={markers}
                mapStyles={mapStyles}
            >
                <MapLegend />
            </BaseMap>
        </MapWrapper>
    );
};
