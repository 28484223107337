import React from 'react';
interface RetailStoreIconProps {
    width: string;
    height: string;
    fill: string;
}

export const RetailStore = ({ width, height, fill }: RetailStoreIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.24021 7.28468H4.64003C3.5343 7.28468 2.61648 7.97658 2.53758 8.86986L1.50534 20.5722C1.46378 21.0447 1.66613 21.5099 2.06482 21.8565C2.46411 22.2032 3.0224 22.4002 3.60778 22.4002H20.2924C20.8778 22.4002 21.4361 22.2032 21.8354 21.8565C22.2341 21.5099 22.4364 21.0447 22.3949 20.5722L21.3626 8.86986C21.2837 7.97658 20.3659 7.28468 19.2602 7.28468H17.6714V7.04088C17.6714 4.48246 15.1101 1.5 11.9501 1.5C8.90574 1.5 6.09205 4.37177 6.22876 7.04088C6.23297 7.12182 6.23659 7.20325 6.24021 7.28468ZM15.8647 7.28468V7.04088C15.8647 5.29041 14.1122 3.31741 11.9501 3.31741C9.78803 3.31741 8.0355 5.29041 8.0355 7.04088V7.28468H15.8647Z"
                fill={fill}
            />
            <path
                d="M16.4952 13.313C16.4952 15.3204 14.461 16.9478 11.9517 16.9478C9.44241 16.9478 7.4082 15.3204 7.4082 13.313"
                stroke="#466BA2"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
