export const mapStyles = [
    {
        featureType: 'all',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                weight: 1.2,
            },
            {
                visibility: 'on',
            },
            {
                color: '#7c4848',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                hue: '#ff0000',
            },
            {
                saturation: '-100',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#e4e6e7',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#f1f3f3',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 21,
            },
            {
                color: '#f1f3f3',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#466ba2',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'geometry.stroke',
        stylers: [
            {
                gamma: '9.33',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'labels.icon',
        stylers: [
            {
                color: '#466ba2',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 21,
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#466ba2',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f8fafc',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ebf0f1',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f8fafc',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ebf0f1',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f8fafc',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ebf0f1',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f8fafc',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ebf0f1',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#b8c9e0',
            },
        ],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on',
            },
            {
                invert_lightness: true,
            },
            {
                color: '#b8c9e0',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
];
