import React from 'react';
interface GroceryStoreIconProps {
    width: string;
    height: string;
    fill: string;
}

export const GroceryStore = ({
    width,
    height,
    fill,
}: GroceryStoreIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6249 5.05718H6.1014L5.74078 3.41576C5.56388 2.59567 4.81186 2 3.95345 2H2.37507C1.89182 2 1.5 2.3821 1.5 2.85429C1.5 3.32649 1.89182 3.70859 2.37507 3.70859H3.95345C3.98935 3.70859 4.02096 3.73361 4.02823 3.76698C3.98955 3.62733 4.35519 5.53775 6.74703 16.1482C6.90768 16.8941 7.59176 17.4363 8.37369 17.4363H19.1805C19.6638 17.4363 20.0556 17.0542 20.0556 16.582C20.0556 16.1099 19.6638 15.7278 19.1805 15.7278H8.44504L8.16176 14.4373H18.8135C19.6623 14.4373 20.4149 13.8735 20.6162 13.1046L22.4364 7.35518C22.5851 6.79997 22.4714 6.21901 22.1125 5.76631C21.7538 5.31351 21.2112 5.05718 20.6249 5.05718Z"
                fill={fill}
            />
            <path
                d="M17.6132 18.5767C16.6473 18.5767 15.8633 19.3483 15.8633 20.2914C15.8633 21.2344 16.6473 21.9999 17.6132 21.9999C18.5793 21.9999 19.3636 21.2344 19.3636 20.2914C19.3636 19.3483 18.5793 18.5767 17.6132 18.5767Z"
                fill={fill}
            />
            <path
                d="M6.9103 18.5767C5.94422 18.5767 5.16016 19.3483 5.16016 20.2915C5.16016 21.2345 5.94422 22 6.9103 22C7.87639 22 8.66045 21.2345 8.66045 20.2915C8.66045 19.3483 7.87639 18.5767 6.9103 18.5767Z"
                fill={fill}
            />
        </svg>
    );
};
