import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H5 } from 'styles/Typography.styled';
import { ArrowDown } from 'shared/svg/arrows';

export const LegendBox = styled.div(
    ({ theme: { colors } }) =>
        css`
            position: absolute;
            z-index: 1000;
            top: 56px;
            left: 56px;
            width: 329px;
            padding: 24px 24px 24px 32px;
            background-color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            ${mediaQueries.sm} {
                width: 100%;
                position: static;
                top: 0;
                left: 0;
                padding: 24px 28px;
                background-color: ${colors.white};
            }
        `,
);

export const TitleButton = styled.button`
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleButtonText = styled(H5)(
    ({ theme: { fontSizes } }) =>
        css`
            font-size: ${fontSizes.f14};
        `,
);

export const Title = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            margin: 0;
            color: ${colors.neutral900};
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
        `,
);
export const ArrowWrapper = styled.div<{ isOpen: boolean }>(
    ({ isOpen }) =>
        css`
            transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0)'};
            transition: all 0.1s linear;
        `,
);

export const List = styled.ul`
    margin: 0;
    padding: 16px 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ListItem = styled.li`
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-of-type {
        padding-bottom: 0;
    }
`;

export const IconWrapper = styled.div<{ isLight?: boolean }>(
    ({ theme: { colors }, isLight }) =>
        css`
            width: 32px;
            height: 32px;
            background-color: ${isLight
                ? colors.primaryBlue300
                : colors.primaryBlue500};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            ${mediaQueries.sm} {
                width: 24px;
                height: 24px;
            }
        `,
);

export const Text = styled.span(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            padding-left: 16px;
            color: ${colors.neutral900};
            font-size: ${fontSizes.f16};
            letter-spacing: ${letterSpacing.s};
            font-weight: ${fontWeight.medium};
            line-height: 28px;
            margin: 0;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
                line-height: 24px;
            }
        `,
);
