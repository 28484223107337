import React from 'react';
interface SchoolIconProps {
    width: string;
    height: string;
    fill: string;
}

export const School = ({ width, height, fill }: SchoolIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.05943 13.2135V16.1125C4.05943 16.8656 4.48033 17.5671 5.15377 17.9282L10.415 20.7447C11.0464 21.0851 11.804 21.0851 12.4354 20.7447L17.6966 17.9282C18.3701 17.5671 18.791 16.8656 18.791 16.1125V13.2135L12.4354 16.618C11.804 16.9584 11.0464 16.9584 10.415 16.618L4.05943 13.2135ZM10.415 3.2476L1.54454 7.99327C0.818486 8.3853 0.818486 9.41697 1.54454 9.809L10.415 14.5547C11.0464 14.8951 11.804 14.8951 12.4354 14.5547L20.8955 10.0256V16.1228C20.8955 16.6902 21.369 17.1545 21.9477 17.1545C22.5265 17.1545 23 16.6902 23 16.1228V9.50982C23 9.1281 22.7895 8.78765 22.4528 8.60195L12.4354 3.2476C12.1245 3.08503 11.7776 3 11.4252 3C11.0729 3 10.7259 3.08503 10.415 3.2476V3.2476Z"
                fill={fill}
            />
        </svg>
    );
};
